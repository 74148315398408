const baseFitmentFields = ['Year', 'Make', 'Model', 'Drive Wheel', 'More Info'];
const extraFitmentFields = [];

const mobileSearchBoxSelector = '#cm-search-box-mobile';

globalThis.Convermax.closeSearchBoxPopup = () => {
  window.document.querySelector('#search-popdown')?.classList.remove('is-visible');
  window.document.body.style.overflow = 'auto';
  window.document.documentElement.removeAttribute('data-scroll-locked');
};

globalThis.Convermax.scrollToTabs = () => {
  const tabsContainer = window.document.querySelector('.product-description .tabs-wrapper');
  const fitmentTab = [...window.document.querySelectorAll('button.accordion__title')].find((tab) =>
    tab.textContent.includes('Fitment'),
  );

  if (tabsContainer && fitmentTab) {
    tabsContainer.scrollIntoView();
    fitmentTab.click();
  }
};

const onSearchBoxSubmit = () => {
  const searchBoxPopup = $('#search-popdown');
  const searchBoxPopupClose = $('.search__predictive__close__inner');

  if (searchBoxPopup.length && $(searchBoxPopup).hasClass('is-visible') && searchBoxPopupClose.length) {
    searchBoxPopupClose.click();
  }

  const searchPage = $('.cm_SearchPage');

  if ($('body').hasClass('cm_search') && searchPage.length) {
    window.setTimeout(() => window.scrollTo(0, $(searchPage).offset().top - $('.theme__header').height()), 0);
  }

  $('body').removeClass('cm_blur');
};

const AfterInit = () => {
  window.addEventListener('click', (e) => {
    const { document } = window;

    if (!e.target.closest(mobileSearchBoxSelector)) {
      document.body.classList.remove('cm_blur');
    }
  });

  window.document.addEventListener('scroll', () => {
    const searchResults = $('.cm_SearchResult');
    const searchHeader = $('.cm_SearchHeader.sticky');

    if (searchResults.length && searchHeader.length) {
      const windowBottom = $(window).scrollTop() + $(window).height();
      const searchResultsBottom = $(searchResults).offset().top + $(searchResults).height();

      // show if SearchResults element is on the screen
      if (
        $(searchResults).offset().top - $('.theme__header').height() > $(window).scrollTop() ||
        searchResultsBottom <= windowBottom
      ) {
        searchHeader.css('display', 'none');
      } else {
        searchHeader.removeAttr('style');
      }
    }
  });
};

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  AfterInit,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isAutoVehicleSelectionDisabled: true,
    isVehicleSelectionIsolated: true,
    onVerifyFitmentInitResponseReceived: (response) => {
      const fits = response.Items?.[0]?.fitsTheVehicle;
      const verifyFitmentGarage = window.document.querySelector('#cm_VerifyFitmentGarage');

      if (verifyFitmentGarage && (!fits || fits === 'yes' || fits === 'no')) {
        verifyFitmentGarage.classList.remove('cm_hide');
      }
    },
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { selector: '#cm_results', class: 'custom--collection' },
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'SearchBox',
      location: {
        selector: '#cm-search-box',
        class: 'cm_search-box-root__desktop search__predictive__main',
      },
      template: 'SearchBox',
      onSubmit: onSearchBoxSubmit,
    },
    {
      /* The client EXPLICITLY asked not to add the dialog */
      name: 'SearchBoxMobile',
      type: 'SearchBox',
      location: {
        selector: mobileSearchBoxSelector,
        class: 'cm_search-box-root__mobile cm_desktop-hide',
      },
      template: 'SearchBox',
      onDropdownItemsReceived: () =>
        window.document.activeElement.closest(mobileSearchBoxSelector) &&
        window.document.body.classList.add('cm_blur'),
      onSubmit: onSearchBoxSubmit,
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low To High',
        'price:desc': 'Price, High To Low',
      },
      doNotHideOnNoResults: true,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 900,
    },
    {
      name: 'HeaderVehicleWidget_hero',
      type: 'VehicleWidget',
      location: {
        firstChildOf: '#cm-hero-ymm',
        class: 'cm_vehicle-widget__search-container',
      },
      template: 'fitmentSearch/heroVehicleWidget',
      isAlwaysActive: false,
      columnBreakpoint: 900,
    },
    {
      name: 'HeroGarage',
      type: 'Garage',
      location: {
        lastChildOf: '#cm-hero-ymm',
        class: 'cm_garage__hero',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'Garage',
      location: '#cm-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        selector: '#cm-mobile-garage',
        class: 'cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { firstChildOf: '#cm-verify-fitment' },
      template: 'fitmentSearch/verifyFitment',
      globalVehicleDiscardEnabled: true,
      columnBreakpoint: 1000,
    },
    {
      name: 'VerifyFitmentGarage',
      type: 'Garage',
      location: {
        lastChildOf: '#cm-verify-fitment',
        class: 'cm_garage__verify-fitment cm_hide',
      },
      template: 'fitmentSearch/garage',
      visibleIf: () => !window.Convermax.universal,
      doNotRedirectOnVehicleSelect: true,
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      initCollapsed: false,
    },
  ],
};
