//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-232:_1422,_5464,_6974,_9200,_5474,_8136,_8868,_3816;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-232')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-232', "_1422,_5464,_6974,_9200,_5474,_8136,_8868,_3816");
        }
      }catch (ex) {
        console.error(ex);
      }