
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatField1(field, fieldIndex) {
        return [_createElement('p', { 'key': '23631' }, this.vehicle[field], fieldIndex < 2 ? [' '] : null)];
    }
    function repeatField2(field, fieldIndex) {
        return [this.vehicle[field] ? _createElement('p', { 'key': '25991' }, ' | ', this.vehicle[field]) : null];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? [
        _createElement('div', { 'key': '3750' }, [_createElement('svg', {
                'width': '24',
                'height': '24',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '4300'
            }, _createElement('path', {
                'd': 'M12.2513 2.25C6.73056 2.25 2.25 6.73056 2.25 12.2513C2.25 17.7719 6.73056 22.2525 12.2513 22.2525C17.7719 22.2525 22.2525 17.7719 22.2525 12.2513C22.2525 6.73056 17.7719 2.25 12.2513 2.25ZM12.2513 20.2523C7.8407 20.2523 4.25025 16.6618 4.25025 12.2513C4.25025 7.8407 7.8407 4.25025 12.2513 4.25025C16.6618 4.25025 20.2523 7.8407 20.2523 12.2513C20.2523 16.6618 16.6618 20.2523 12.2513 20.2523ZM16.8418 7.8307L10.251 14.4215L7.66068 11.8412L6.2505 13.2514L10.251 17.2519L18.252 9.25088L16.8418 7.8307Z',
                'fill': '#4C803C'
            }))]),
        ' This product will fit your selected vehicle'
    ] : null, this.fits === 'no' ? [
        _createElement('div', { 'key': '11620' }, [_createElement('svg', {
                'width': '24',
                'height': '24',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '12160'
            }, _createElement('path', {
                'd': 'M14.5896 7.99977L11.9993 10.5901L9.40897 7.99977L7.9988 9.40995L10.5891 12.0003L7.9988 14.5906L9.40897 16.0008L11.9993 13.4104L14.5896 16.0008L15.9998 14.5906L13.4095 12.0003L15.9998 9.40995L14.5896 7.99977ZM11.9993 1.99902C6.46861 1.99902 1.99805 6.46958 1.99805 12.0003C1.99805 17.531 6.46861 22.0015 11.9993 22.0015C17.53 22.0015 22.0005 17.531 22.0005 12.0003C22.0005 6.46958 17.53 1.99902 11.9993 1.99902ZM11.9993 20.0013C7.58875 20.0013 3.9983 16.4108 3.9983 12.0003C3.9983 7.58972 7.58875 3.99927 11.9993 3.99927C16.4098 3.99927 20.0003 7.58972 20.0003 12.0003C20.0003 16.4108 16.4098 20.0013 11.9993 20.0013Z',
                'fill': '#CC0000'
            }))]),
        ' This product will not fit your selected vehicle'
    ] : null, this.fits === 'aggressive' ? ['This product may require modifications to fit your'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_vehicle' },
        _map([
            'Year',
            'Make',
            'Model'
        ], repeatField1.bind(this)),
        _map([
            'Drive Wheel',
            'More Info'
        ], repeatField2.bind(this))
    ])), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button btn btn--secondary',
        'onClick': this.discardVehicle
    }, '\n              clear vehicle selection\n            ')))))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cmTemplate_universal',
        'key': '3115'
    }, window.Convermax.universal === 'Trailer' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal',
        'key': '3202'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, _createElement('div', {}, [_createElement('svg', {
            'width': '24',
            'height': '24',
            'viewBox': '0 0 24 24',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '34420'
        }, _createElement('path', {
            'd': 'M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z',
            'stroke': '#C69E25',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z',
            'fill': '#C69E25'
        }), _createElement('line', {
            'x1': '11.75',
            'y1': '13.25',
            'x2': '11.75',
            'y2': '7.75',
            'stroke': '#C69E25',
            'strokeWidth': '2',
            'strokeLinecap': 'round'
        }))]), ' This product is for trailers, and will require you to manually verify fitment'), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button btn btn--secondary',
        'onClick': () => window.Convermax.scrollToTabs()
    }, '\n              Verify Fitment\n            '))))) : null) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '4507'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, _createElement('div', {}, [_createElement('svg', {
            'width': '24',
            'height': '24',
            'viewBox': '0 0 24 24',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '47470'
        }, _createElement('path', {
            'd': 'M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z',
            'stroke': '#C69E25',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z',
            'fill': '#C69E25'
        }), _createElement('line', {
            'x1': '11.75',
            'y1': '13.25',
            'x2': '11.75',
            'y2': '7.75',
            'stroke': '#C69E25',
            'strokeWidth': '2',
            'strokeLinecap': 'round'
        }))]), ' This product doesn\u2019t have any vehicle fitment data'), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('a', {
        'href': '/pages/contact',
        'target': '_blank',
        'className': 'cm_vehicle-widget_button btn btn--secondary'
    }, '\n            Contact Us\n          '))))) : null);
}
        export const componentNames = []